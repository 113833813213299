import { CardHeader, Chip, IconButton, Spinner, Tooltip, Typography } from '@material-tailwind/react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Mycard from '../../../../../../COmponents/Mycard'

import { FaEdit } from 'react-icons/fa';
import { SiTarget } from 'react-icons/si';

import gpeapi from '../../../../../../apis/gpeapi'
import Myselect from '../../../../../../COmponents/Myselect'
import { useDispatch, useSelector } from 'react-redux'

import Myinput from '../../../../../../COmponents/Myinput'
import Page404Found from '../../../../../../COmponents/Page404Found'
import { fetchpublisher } from '../../../../../../Redux/Actions/Agency/publisheragencyaction'
import { COLORS } from '../../../../../../Constants'
import { Authcontext } from '../../../../../../Context/Authcontext'


const ViewCamp = () => {
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const { id } = useParams()
    const { toggle } = useContext(Authcontext)
    const [loading, setloading] = useState(false)
    const { platformurl } = useParams()
    const navigate = useNavigate()
    const [found, setfound] = useState(null)
    const userinfo = JSON.parse(localStorage.getItem('userInfo'))

    const [mydta, setmydta] = useState()
    const [advloading, setadvloading] = useState(false)

    const basicDta = useSelector((state) => state.basicReducer)[0]
    const [publoading, setpubloading] = useState(false)

    const [pubid, setpubid] = useState('')
    const [pubtracking, setpubtracking] = useState('')
    const [mypostback, setmypostback] = useState(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${mydta?.security_token}`)
    const [mygoal, setmygoal] = useState('')

    const [mypostback1, setmypostback1] = useState(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${mydta?.security_token}`)

    const [mypostback2, setmypostback2] = useState(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${mydta?.security_token}`)



    
    const [tparams, settparams] = useState({
        sub1: '',
        sub2: '',
        sub3: '',
        sub4: ''
    })

    const handlechnage = (e) => {
        const name = e.target.name
        const value = e.target.value

        settparams({ ...tparams, [name]: value })
    }

    

    const [pendingrequestload, setpendingrequestload] = useState(false)
    const [pendingrequestdta, setpendingrequestdta] = useState()

    useEffect(() => {
        callforReducer()

    }, [])

    const [pubdatata,setpubdata]= useState([])

    const callforReducer = async () => {
        setadvloading(true)
        const res = await gpeapi.get(`/${platformurl}/adm/getpublishers?limit=300`, {
            headers: {
              "Authorization": `Beaer ${token}`
            }
          }).then((ress) => {
      
            setpubdata(ress?.data?.TotalUsers?.docs)
          }).catch((e) => {
            toast.error(e?.response?.data?.msg)
      
          })
        setadvloading(false)
    }

    const advertidata = []

    var select_goal = []

    const [goadta, setgoadta] = useState([])

    // useEffect(()=>{
    //     updateforgoals()
    // },[mydta?.goals?.length])

    // const updateforgoals = async()=>{
    //     await mydta?.goals?.map((val,i,arr)=>(
    //         setgoadta([...goadta,{label:arr[i].title,value:arr[i].title}])
    //         // console.log(arr[i].title)
    //     ))
    // }

    // const pubdatata = useSelector((state) => state.publisherAgencyReducer)[0]?.docs


    const advertiserdta = useSelector((state) => state.publisherAgencyReducer)[0]?.docs?.filter((mval) => mval.ac_status == 'active').map((val) => advertidata.push({ label: `${val.name} (${val.company})`, value: val.id }))

    useEffect(() => {
        callforApi()
        fetchdatapendingrequest()
    }, [])

    // console.log(pubdatata)


    const callforApi = async () => {
        setloading(true)
        const res = await gpeapi.get(`/${platformurl}/adm/getcampaign/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setmydta(ress?.data?.user)
            setfound(true)
            // console.log(basicDta)
            setmypostback(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${ress?.data?.user?.security_token}`)
            setmypostback1(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${ress?.data?.user?.security_token}`)
            setmypostback2(`${basicDta?.serverurl}/${platformurl}/acquisition?clickid={REPLACE_YOUR_CLICKID}&security_token=${ress?.data?.user?.security_token}`)
            var mydttt = ress?.data?.user

            if (ress?.data?.success) {

                select_goal?.length == 0 && mydttt?.goals?.map((val, i) => select_goal.push({ label: val.title, value: val.value }))

                setgoadta(select_goal)
            }
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)
            setfound(false)
        })
        // console.log('goals',mydta?.goals)
        //        const myrs = await mydta?.goals?.map((val, i, arr) => {
        //            return select_goal.push({ label: arr[i].title, value: arr[i].title })
        //             // console.log(arr[i].title)
        //        })
        //         setgoadta(select_goal)
        // console.log(select_goal)
        // console.log(myrs)


        setloading(false)

    }

    const fetchdatapendingrequest = async () => {
        setpendingrequestload(true)
        const res = await gpeapi.get(`/${platformurl}/adm/getpendingrequestcamp/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            setpendingrequestdta(ress?.data?.mydta)

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setpendingrequestload(false)

    }



    const getdatetimevia = (date) => {
        const mydate = new Date(date)
        const todate = mydate.getDate()
        const month = mydate.getMonth()
        const year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        return `${todate}-${month + 1}-${year} at ${strTime}`
    }

    const handlechangefoTracking = async (pubid) => {
        setpubloading(true)
        const res = await gpeapi.get(`/${platformurl}/adm/getcampaignurlbypub/${pubid}/${id}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            toast.success(ress.data.msg)

            setpubtracking(ress.data.mydata?.mycampaignurl)
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setpubloading(false)
    }



    const convertpubidtopubdata = (id) => {

        const fillter = pubdatata?.filter((val) => val.id == id)[0]
        return fillter
    }


    const handlependingrequetchange = async (e, pubid, campid) => {
        setpendingrequestload(true)
        const res = await gpeapi.put(`/${platformurl}/adm/updatependingrequestcamp/${pubid}/${campid}`, { status: e }, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {
            fetchdatapendingrequest()
            toast.success(ress?.data?.msg)

        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setpendingrequestload(false)
    }
    // console.log(pendingrequestdta)

    const handleupdatetrackigparams =  () => {
       
        setmypostback(`${mypostback1}${tparams.sub1 && `&sub1=${tparams.sub1}`}${tparams.sub2 && `&sub2=${tparams.sub2}`}${tparams.sub3 && `&sub3=${tparams.sub3}`}${tparams.sub4 && `&sub4=${tparams.sub4}`}`)
    }

    return (
        <div className={toggle ? "main active" : "main"}>

            <ToastContainer />

            {loading ? <div className='flex justify-center items-center'>
                <Spinner className="h-10 w-10  text-blue-500/10" />
            </div>
                :


                mydta ? <div className='sm:flex sm:flex-col md:flex md:flex-row md:flex-wrap'>

                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div className='flex justify-between items-center border-b p-5 pb-1'>
                                <Typography variant='h5' >
                                    Details (<span className='text-gray-400 italic'>ID:</span> {id})
                                </Typography>

                                <div>
                                    <p onClick={() => navigate(`/offerborn/amd/admin/agencylogin/${platformurl}/updatecamp/${id}`)} style={{ backgroundColor: COLORS.primary }} className='pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer'>
                                        <Tooltip content="Edit">
                                            <p  >
                                                <FaEdit className="h-4 w-4" color='white' />
                                            </p>
                                        </Tooltip>
                                    </p>
                                </div>
                            </div>

                            <div className='p-5 pt-2'>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-1'>

                                    <p className='text-sm'>Title</p>

                                    <p className='text-sm col-span-3'>{mydta?.title}</p>
                                </div>
                                <div className='justify-center grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Model</p>

                                    <p className='text-sm col-span-3'>{mydta?.campaignmodel}</p>
                                </div>
                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Visibility</p>

                                    <p className='text-sm col-span-3'>{mydta?.visibility}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Advertiser</p>

                                    <p className='text-sm col-span-3 text-blue-500 cursor-pointer'>(ID: {mydta?.advertiserid}) {mydta?.advertiser}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Status</p>

                                    <Chip

                                        size="sm"
                                        variant="ghost"
                                        value={mydta?.status}
                                        color={
                                            mydta?.status === "active" ? "green" : mydta?.status === "pending" ? "amber" : "red"
                                        }
                                    />

                                    {/* <p className='text-sm col-span-3'>{mydta?.visibility}</p> */}
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Currency</p>

                                    <p className='text-sm col-span-3 uppercase'>{mydta?.currency}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Preview URL</p>

                                    <p className='text-sm col-span-3  text-blue-500 cursor-pointer'>{mydta?.previewurl}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>URL</p>

                                    <p className='text-sm col-span-3 text-blue-500 cursor-pointer'>{mydta?.campaignurl}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Devices</p>

                                    <p className='text-sm col-span-3'>{mydta?.devices}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Category</p>

                                    <p className='text-sm col-span-3'>{mydta?.category}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Created</p>

                                    <p className='text-sm col-span-3'>{getdatetimevia(mydta?.createdAt)}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Updated</p>

                                    <p className='text-sm col-span-3'>{getdatetimevia(mydta?.updatedAt)}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Unique ID</p>

                                    <p className='text-sm col-span-3 text-blue-400'>{mydta?._id}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>Description</p>

                                    <p className='text-sm col-span-3 '>{mydta?.des}</p>
                                </div>

                                <div className='grid grid-cols-4  gap-4  border-b pb-2 pt-2'>

                                    <p className='text-sm'>KPI</p>

                                    <p className='text-sm col-span-3 '>{mydta?.kpi}</p>
                                </div>

                            </div>


                        </div>
                    } />

                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div className='flex justify-between items-center border-b p-5 pb-1'>
                                <Typography variant='h5' >
                                    Tracking Link
                                </Typography>

                                <div>
                                    <p onClick={() => navigate(`/offerborn/amd/admin/agencylogin/${platformurl}/updatecamp/${id}`)} style={{ backgroundColor: COLORS.primary }} className='pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer'>
                                        <Tooltip content="Reports">
                                            <p className='font-semibold text-white text-sm'>
                                                Report
                                            </p>
                                        </Tooltip>
                                    </p>
                                </div>
                            </div>

                            {mydta?.visibility == 'private' ? (
                                <label className='p-5 pt-2'>This campaign is Private</label>
                            ) : (



                                <div className='p-5 pt-2'>
                                    <div className='grid grid-cols-4  gap-4   pb-2 pt-2'>
                                        {advloading ? <Spinner /> :
                                            <div className='text-sm col-span-4 text-blue-400'>



                                                <Myselect tips={'To generate a tracking link, select an affiliate from below. Tracking links records click for reporting.'} value={pubid} data={advertidata} width={'100%'} placeholder={'Select Publisher'} onChange={(e) => {
                                                    setpubid(e)
                                                    handlechangefoTracking(e)
                                                }} />
                                            </div>
                                        }
                                    </div>

                                    <div className='grid flex-col grid-cols-4  gap-4   pb-2 pt-2'>
                                        {publoading ? <Spinner /> :
                                            <div className='text-sm col-span-4'>

                                                <div>
                                                    <p className='text-lg'>Generated Link</p>
                                                </div>
                                                <Myinput width={'100%'} value={pubtracking} multiline={true} type={'text'} onChange={(e) => setpubtracking(e.target.value)} />
                                            </div>
                                        }
                                    </div>


                                </div>

                            )}
                        </div>
                    } />

                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div>
                                <div className='flex justify-between items-center border-b p-5 pb-1'>
                                    <Typography variant='h5' >
                                        Revenue, Payout and Goals
                                    </Typography>

                                    <div>
                                        <p onClick={() => navigate(`/offerborn/amd/admin/agencylogin/${platformurl}/updatecamp/${id}`)} style={{ backgroundColor: COLORS.primary }} className='pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer'>
                                            <Tooltip content="Add Goals">
                                                <p className='font-semibold text-white text-sm'>
                                                    Add Goals
                                                </p>
                                            </Tooltip>
                                        </p>
                                    </div>
                                </div>

                                <div className='p-5 pt-2'>

                                    <div className='grid grid-cols-6  gap-4  border-b pb-2 pt-1'>

                                        <p className='text-sm col-span-3 font-bold'>GOAL</p>

                                        <p className='text-sm font-bold text-center'>VALUE</p>
                                        <p className='text-sm font-bold text-center'>REVENUE</p>
                                        <p className='text-sm font-bold text-center'>PAYOUT</p>
                                    </div>

                                    <div className='grid grid-cols-6  gap-4  border-b pb-2 pt-1'>

                                        <p className='text-sm col-span-3 '>Default</p>

                                        <p className='text-sm text-center'>{mydta?.default_goal}</p>
                                        <p className='text-sm text-center'>₹{mydta?.revenue}</p>
                                        <p className='text-sm text-center'>₹{mydta?.payout}</p>
                                    </div>

                                    {
                                        mydta?.goals && mydta?.goals?.map((val, i) => (
                                            <div key={i} className='grid grid-cols-6  gap-4  border-b pb-2 pt-1 justify-center items-center'>

                                                <p className='text-sm col-span-3 ' style={{ color: COLORS.primary }}>{val.title} <p className='text-xs '>{val?.id}</p></p>

                                                <p className='text-sm text-center'>{val?.value}</p>
                                                <p className='text-sm text-center'>₹{val?.rev}</p>
                                                <p className='text-sm text-center'>₹{val?.po}</p>
                                            </div>
                                        ))
                                    }




                                </div>


                            </div>
                        </div>
                    } />

                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div>
                                <div className='flex justify-between items-center border-b p-5 pb-1'>
                                    <Typography variant='h5' >
                                        Postback Setup
                                    </Typography>


                                </div>

                                <div className='p-5 pt-2'>

                                    <div className='grid grid-cols-4  gap-4   pb-2 pt-2'>
                                        {advloading ? <Spinner /> :


                                            mydta?.goals &&
                                            <div className='text-sm col-span-4 text-blue-400'>
                                                <Myselect value={mygoal} data={goadta} width={'100%'} placeholder={'Select Goal'} onChange={(e) => {
                                                   if (!mypostback.includes(`goal=${e}`)) {
                                                    setmygoal(e)
                                                    setmypostback(mypostback2 + `&goal=${e}`)
                                                    setmypostback1(mypostback2 + `&goal=${e}`)
                                                    settparams({
                                                        sub1:'',
                                                        sub2:'',
                                                        sub3:'',
                                                        sub4:''
                                                    })

                                                }
                                                }} />
                                            </div>
                                        }
                                    </div>

                                    <div className='grid flex-col grid-cols-4  gap-4   pb-2 pt-2'>
                                        {advloading ? <Spinner /> :
                                            <div className='text-sm col-span-4'>



                                                <div>
                                                    <p className='text-lg'>Generated Postback</p>
                                                </div>
                                                <Myinput width={'100%'} line={5} value={mypostback} multiline={true} type={'text'} onChange={(e) => setmypostback(e.target.value)} />
                                            </div>
                                        }
                                    </div>

                                    <div className='grid flex-col grid-cols-4  gap-4   pb-2 pt-2'>

                                        <div className='text-sm col-span-4'>

                                            <div>
                                                <p className='text-md'>Macros</p>
                                            </div>

                                            <div className='flex '>

                                                <Myinput width={'50%'} value={tparams.sub1} placeholder={'Macro 1'} name='sub1' type={'text'} onChange={handlechnage} />
                                                <Myinput width={'50%'} value={tparams.sub2} placeholder={'Macro 2'} name='sub2' type={'text'} onChange={handlechnage} />



                                            </div>

                                            <div className='flex'>
                                                <Myinput width={'50%'} value={tparams.sub3} placeholder={'Macro 3'} name='sub3' type={'text'} onChange={handlechnage} />
                                                <Myinput width={'50%'} value={tparams.sub4} placeholder={'Macro 4'} name='sub4' type={'text'} onChange={handlechnage} />
                                            </div>

                                            <div className=' flex justify-end'>
                                                <p onClick={handleupdatetrackigparams} style={{ backgroundColor: COLORS.primary }} className='pl-3 pr-3 pt-1 pb-1 rounded-full cursor-pointer'>

                                                    <p className='font-semibold text-white text-sm'>
                                                        UPDATE
                                                    </p>

                                                </p>
                                            </div>


                                        </div>

                                    </div>




                                </div>


                            </div>
                        </div>
                    } />


                    <Mycard classname={'sm:w-full md:w-[47%]'} compo={
                        <div>
                            <div>
                                <div className='flex justify-between items-center border-b p-5 pb-1'>
                                    <Typography variant='h5' >
                                        Pending Request Publishers
                                    </Typography>


                                </div>

                                <div className='p-5 pt-2'>

                                    <div className='grid grid-cols-6  gap-4  border-b pb-2 pt-1'>

                                        <p className='text-sm col-span-2 font-bold'>Publisher</p>

                                        <p className='text-sm font-bold text-center'>Status</p>
                                        <p className='text-sm col-span-2 font-bold text-center'>Comment</p>
                                        <p className='text-sm font-bold text-center'>Change</p>
                                    </div>

                                    <div className='grid grid-cols-4  gap-4   pb-2 pt-2'>
                                        {pendingrequestload ? <Spinner /> :




                                            pendingrequestdta?.length ?

                                                <div className='text-sm col-span-4 '>

                                                    {
                                                        pendingrequestdta?.map((val, i) => (
                                                            <div key={i} className='grid grid-cols-6  gap-4  border-b pb-2 pt-1 justify-center items-center'>

                                                                <p className='text-sm col-span-2 ' style={{ color: COLORS.primary }}>{convertpubidtopubdata(val?.pubid)?.name}  <p className='text-xs '>{convertpubidtopubdata(val?.pubid)?.company}</p></p>

                                                                <p className='text-sm text-center'>{val?.status}</p>
                                                                <p className='text-xs col-span-2 text-center'>{val?.comment}</p>
                                                                <select onChange={(e) => handlependingrequetchange(e.target.value, val.pubid, val.campid)}>
                                                                    <option value="" selected disabled hidden>Select here</option>
                                                                    <option value={'pending'}>Pending</option>
                                                                    <option value={'public'}>Approve</option>
                                                                </select>
                                                            </div>
                                                        ))
                                                    }




                                                </div>:
                                                <p className='col-span-3'>No Request Found</p>
                                        }
                                    </div>

                                    {/* <div className='grid flex-col grid-cols-4  gap-4   pb-2 pt-2'>
                                        {advloading ? <Spinner /> :
                                            <div className='text-sm col-span-4'>



                                                <div>
                                                    <p className='text-lg'>Generated Postback</p>
                                                </div>
                                                <Myinput width={'100%'} line={5} value={mypostback} multiline={true} type={'text'} onChange={(e) => setmypostback(e.target.value)} />
                                            </div>
                                        }
                                    </div> */}




                                </div>


                            </div>
                        </div>
                    } />




                </div>
                    :
                    <Page404Found />
            }
        </div>

    )
}

export default ViewCamp