
import React from 'react'
import { useSelector } from 'react-redux'
import { COLORS } from '../../Constants'
import { Link } from 'react-router-dom'

const Footerweb = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]
    
  return (
    <footer class="footer" style={{backgroundColor:COLORS.primary,paddingLeft:20}}>
    <div class="">
        <div class="row">
            <div class="footer-col mr-11">
                <img style={{margin:'auto',marginTop:1,marginBottom:13}} width='250' height='50' src='https://res.cloudinary.com/dcpn2tcwm/image/upload/v1696658866/offerborn/logos/offerborn_big_logo_transparent_white_color_sv68ex.png'/>
                <p style={{color:'white',fontSize:14,fontWeight:500}} className='capitalize'>
                Founded in 2023, OfferBorn was made with a thought of ease and affordability in mind. We are a performance marketing Platform aiming to grow mutually with our partners and provide a platform where you save on your marketing expenses and expand your Horizons.
                </p>
            </div>
            <div class="footer-col ml-2">
                <h4>Business Hours</h4>
                <div>
                     <p style={{color:'white',fontWeight:'bold',fontSize:15}}>Open Days:</p>
                     <p style={{color:'white',fontSize:14}}>Monday - Friday :9AM to 6PM</p>
                     <p style={{color:'white',fontSize:14}}>Saturday : 9AM to 5PM</p>
                </div>
                <div>
                     <p style={{color:'white',fontWeight:'bold',fontSize:15}}>Vacations:</p>
                     <p style={{color:'white',fontSize:14}}>All Sunday Holiday</p>
                     <p style={{color:'white',fontSize:14}}>All Official Holidays</p>
                </div>
            </div>
            <div class="footer-col">
                <h4>Important Links</h4>
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-&-conditions">Terms & Conditions</a></li>
                    <li><a href="/refund-policy">Cancellation and Refund Policy</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4>follow us</h4>
                <div class="social-links">
                    <a href="https://www.facebook.com/offerborn" target='_blank'><i class="fab fa-facebook-f"></i></a>
                    <a href="https://twitter.com/offerborn" target='_blank'><i class="fab fa-twitter"></i></a>
                    <a href="https://www.instagram.com/offerborn/" target='_blank'><i class="fab fa-instagram"></i></a>
                    <a href="https://www.linkedin.com/company/offerborn/" target='_blank'><i class="fab fa-linkedin-in"></i></a>
                </div>
            </div>
            <div class="footer-col">
                <h4 >Resources</h4>
                <ul>
                    <li><a target='_blank' href="https://knowledgebase.offerborn.com/">Knowledge Base</a></li>
                    <li><a target='_blank' href="https://offerborn.readme.io/reference/get-all-campaigns">Agency API Docs</a></li>
                    <li><a target='_blank' href="https://offerborn.readme.io/reference/get-publisher-profile">Publisher API Docs</a></li>
                    <li><a target='_blank' href="https://offerborn.readme.io/reference/get-advertiser-profile">Advertiser API Docs</a></li>
                </ul>
            </div>
        </div>
        
       
        <p style={{color:'white',marginTop:30,fontSize:15}} class="copyright">
        &copy; <a href="/" class="copyright-link">OfferBorn </a> {new Date().getFullYear()} All Rights Reserved 
      </p>
    </div>
</footer>
  )
}

export default Footerweb