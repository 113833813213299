import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const Refundpolicy = () => {

    const basicData = useSelector((state) => state.basicReducer)[0]

    document.title = `Cancellation and Refund Policy - OfferBorn`

    useEffect(()=>{
      window.scrollTo(0,0)
  },[])
  return (
    <section style={{marginTop:50}} class="section contact" id="contact" aria-label="contact">
    <div class="container">

      <h2 class="h3 section-title font-bold">Cancellation and Refund Policy</h2>

      <p class="section-text">
      
      </p>


      <h1 >Cancellation and Refund Policy for OfferBorn</h1>

      <p>
      This Cancellation / Refund Policy applies to all individuals, businesses, organizations, resellers, and end-clients/users employing any OfferBorn  services, hardware, property or other products.Use of OfferBorn  resources constitutes acceptance of all policies in effect at the time of use. Modification to this Cancellation / Refund Policy may be made at any time and will be immediately effective upon posted changes.OfferBorn reserves the right to protect its resources, users, and reputation from any activity deemed damaging, offensive or abusive. To this measure, OfferBorn will act as sole arbiter in determining violations of this policy.

 
      </p>
      <p>
      Cancellation / Refund Policy – You are most welcome to communicate with our sales team via chat / ticket to ensure the best suitable Plan for your project , invoice paid will not be canceled  and even you are canceling it there is no refund option. As we have ZERO setup Fee  for our customers , we don’t charge  for Server Setup .Also have 15 Days free trial . You can try first and then go for order .
      </p>

      <p>
      All Sales Are Final. We do not offer refunds under any circumstances as we provide digital products and services including downgrading, so returns and exchanges do not apply. 
      We do not offer any kind of returns or exchanges.
      </p>

 </div>
      </section>
  )
}

export default Refundpolicy


