
import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'

import { PencilIcon, TrashIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchadvertiser } from '../../../Redux/Actions/Agency/advertiseragencyaction'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import Mypopup from '../../../COmponents/Mypopup'
import gpeapi from '../../../apis/gpeapi'
import Mypaginate from '../../../COmponents/Mypaginate'
import { fetchcampaign } from '../../../Redux/Actions/Agency/campaignagencyaction'
import { fetchpricing } from '../../../Redux/Actions/pricingaction'
import Mainaccountagency from '../../../Agency/Pages/Account/Mainaccountagency'
import { FaCheckCircle } from 'react-icons/fa'
import { ImCross } from "react-icons/im";


const ActiveAgencyclicks = () => {
    const token = localStorage.getItem('token')
    const navigate = useNavigate()
    const { platformurl } = useParams()
    const dispatch = useDispatch()
    const { toggle } = useContext(Authcontext)
    const [openmodal, setopenmodal] = useState(false)
    const [openpop, setopenpop] = useState(false)

    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState()
    const [limit, setlimit] = useState(20)
    const [search, setsearch] = useState('')


    useEffect(() => {
        callforAPI()
    }, [limit])

    const [showpaid, setshowpaid] = useState(false)

    const callforAPI = async () => {
        setloading(true)
        const res = await gpeapi.get(`/admagencyrunclicktoday`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            setstatdata(ress?.data?.user)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

 
    const TABLE_HEAD = ["Count","Agency ID" ];



    return (
        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
            {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



            <ToastContainer />




            <p className='text-2xl font-medium'>MANAGE AGENCY ACTIVE CLICKS</p>

            


            {
                loading ? <div className='flex justify-center items-center'>
                    <Spinner className="h-10 w-10  text-blue-500/10" />
                </div> : (
                    statsdata && statsdata?.length == 0 ? <Typography variant="paragraph" className='text-center'>NO DATA FOUND</Typography> :
                        <div>
                            <CardBody className="overflow-scroll px-0">
                                <table className="w-full min-w-max table-auto text-left">
                                    <thead>
                                        <tr>
                                            {TABLE_HEAD.map((head) => (
                                                <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal leading-none opacity-70"
                                                    >
                                                        {head}
                                                    </Typography>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {statsdata && statsdata?.map(
                                            (val, index) => {
                                                const isLast = index === statsdata?.length - 1;
                                                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                                return (
                                                    <tr key={index}>
                                                        <td className={classes}>


                                                            <Typography variant="small" color="blue-gray" className="font-noraml">
                                                                {index+1}
                                                            </Typography>

                                                        </td>

                                                        <td className={classes}>


                                                            <Typography variant="small" color="blue-gray" className="font-bold">
                                                                {val}
                                                            </Typography>

                                                        </td>
                                                        





                                                    </tr>
                                                );
                                            },
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                            





                        </div>
                )
            }






        </div>
    )
}

export default ActiveAgencyclicks

